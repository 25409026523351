function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    return Constructor;
}
var VideoPoint = function () {
    function VideoPoint(player) {
        var _this = this;
        _classCallCheck(this, VideoPoint);
        this.player = player;
        this.player.template.mask.addEventListener('click', function () {
            _this.hide();
        });
        var _loop = function _loop(i) {
            _this.player.template.pointItem[i].addEventListener('click', function () {
                _this.player.setVideoPoint(_this.player.template.pointItem[i].dataset.point, i);
                _this.hide();
            });
        };
        for (var i = 0; i < this.player.template.pointItem.length; i++) {
            _loop(i);
        }
    }
    _createClass(VideoPoint, [
        {
            key: 'hide',
            value: function hide() {
                var _this2 = this;
                this.player.template.settingBox.classList.remove('dplayer-setting-box-open');
                this.player.template.mask.classList.remove('dplayer-mask-show');
                setTimeout(function () {
                    _this2.player.template.settingBox.classList.remove('dplayer-setting-box-narrow');
                    _this2.player.template.settingBox.classList.remove('dplayer-setting-box-point');
                }, 300);
                this.player.controller.disableAutoHide = false;
            }
        },
        {
            key: 'show',
            value: function show() {
                this.player.template.settingBox.classList.add('dplayer-setting-box-open');
                this.player.template.mask.classList.add('dplayer-mask-show');
                this.player.controller.disableAutoHide = true;
            }
        }
    ]);
    return VideoPoint;
}();
export default VideoPoint;