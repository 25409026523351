function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    return Constructor;
}
var Speed = function () {
    function Speed(player) {
        var _this = this;
        _classCallCheck(this, Speed);
        this.player = player;
        this.player.template.mask.addEventListener('click', function () {
            _this.hide();
        });
        this.player.template.speedButton.addEventListener('click', function () {
            _this.player.template.settingBox.classList.remove('dplayer-setting-box-point');
            setTimeout(function () {
                _this.show();
                _this.player.template.settingBox.classList.add('dplayer-setting-box-narrow');
                _this.player.template.settingBox.classList.add('dplayer-setting-box-speed');
            }, 300);
        });
        var _loop = function _loop(i) {
            _this.player.template.speedItem[i].addEventListener('click', function () {
                var speedNum = _this.player.template.speedItem[i].dataset.speed;
                _this.player.speed(speedNum);
                _this.player.template.speedButtonText.innerHTML = speedNum == 1 ? '正常' : speedNum + 'x';
                _this.hide();
            });
        };
        for (var i = 0; i < this.player.template.speedItem.length; i++) {
            _loop(i);
        }
    }
    _createClass(Speed, [
        {
            key: 'hide',
            value: function hide() {
                var _this2 = this;
                this.player.template.settingBox.classList.remove('dplayer-setting-box-open');
                this.player.template.mask.classList.remove('dplayer-mask-show');
                setTimeout(function () {
                    _this2.player.template.settingBox.classList.remove('dplayer-setting-box-narrow');
                    _this2.player.template.settingBox.classList.remove('dplayer-setting-box-speed');
                }, 300);
                this.player.controller.disableAutoHide = false;
            }
        },
        {
            key: 'show',
            value: function show() {
                this.player.template.settingBox.classList.add('dplayer-setting-box-open');
                this.player.template.mask.classList.add('dplayer-mask-show');
                this.player.controller.disableAutoHide = true;
            }
        }
    ]);
    return Speed;
}();
export default Speed;